import { getParams } from "../getParams";
import { SCRIPT_MATCHER } from "../constants";

export const getMinPriceProduct = (metaObj) => {
  const sortedObject = metaObj?.product?.variants?.sort((v1, v2) =>
    v1.price > v2.price ? 1 : v1.price < v2.price ? -1 : 0
  );
  return sortedObject && sortedObject.length > 0 ? sortedObject[0] : {};
};

export const getAppContext = () => {
  return {
    ...(window.Shopify
      ? toLatitudeCheckout(window.Shopify, window.meta)
      : cleanLatitudeCheckout(
          window.LatitudeCheckout,
          window.LatitudeCheckoutOverride
        )),
  };
};

const toLatitudeCheckout = (shopify, meta) => {
  if (!shopify) {
    return {};
  }
  const params = getParams(SCRIPT_MATCHER);
  let latitudeCheckout = {
    merchantId: shopify?.shop,
    container: params?.containerId,
    currency: shopify?.currency?.active,
    page: meta?.page?.pageType,
    product: getMinPriceProduct(meta),
    layout: params?.layout,
    promotionCode: params?.promotionCode,
    promotionMonths: params?.promotionMonths,
  };
  return latitudeCheckout;
};

const cleanLatitudeCheckout = (latitudeCheckout, latitudeCheckoutOverride) => {
  if (!latitudeCheckout) {
    return {};
  }
  let cleanedlatitudeCheckout = {
    merchantId: latitudeCheckout?.merchantId,
    container: latitudeCheckout?.container,
    currency: latitudeCheckout?.currency,
    page: latitudeCheckout?.page,
    product: latitudeCheckout?.product,
    ...latitudeCheckoutOverride?.productWidget,
  };
  return cleanedlatitudeCheckout;
};

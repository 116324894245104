import React from "react";
import { Container, List, Stack, Text } from "basis";

import { StyledPre } from "../../../../components";

const Direct = ({ container = {}, script = {} }) => (
  <List type="steps">
    <List.Item>
      <Container padding="0 0 4 0">
        <Text>
          Add a container div where you would like to render the widget.
        </Text>
      </Container>
      <Container bg="white">
        <StyledPre>{container}</StyledPre>
      </Container>
    </List.Item>

    <List.Item>
      <Container padding="0 0 4 0">
        <Stack gap="4">
          <Text>
            Add this script on the page (preferably in the head section) where
            you would like to show the widget.
          </Text>

          <Text>
            <strong>Note:</strong> Please make sure that merchant id and product
            attributes are added dynamically.
          </Text>
        </Stack>
      </Container>

      <Container bg="white">
        <StyledPre>{script}</StyledPre>
      </Container>
    </List.Item>
  </List>
);

export default Direct;

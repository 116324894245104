import React from "react";
import { Container, List, Stack, Text } from "basis";

import { StyledPre } from "../../../../components";

const WooCommerce = ({ jsonScript = {} }) => (
  <List type="steps">
    <List.Item>
      <Container padding="0 0 4 0">
        <Text>Install and activate WooCommerce Plugin.</Text>
      </Container>
    </List.Item>

    <List.Item>
      <Container padding="0 0 4 0">
        <Stack gap="4">
          <Text>Add this JSON in</Text>

          <Text textStyle="body2">
            WooCommerce &gt; Settings &gt; Payments &gt; Latitude Interest Free
            &gt; Widget Settings.
          </Text>
        </Stack>
      </Container>

      <Container bg="white">
        <StyledPre>{jsonScript}</StyledPre>
      </Container>
    </List.Item>
  </List>
);

export default WooCommerce;

import React, { useEffect, useState } from "react";
import { Form } from "basis";

import { InfoCard, Scene } from "../../layout/playground";
import {
  Configuration,
  Instructions,
  AdvancedConfig,
  Preview,
} from "../../layout/playground/widget";

import WidgetTesting from "./widget-testing";
import { appUrl } from "../../core/config";
import { PAYMENT_OPTIONS } from "../../core/constants";
import { LAYOUT_TYPE } from "../../../assets/components/product";

const Widget = () => {
  const [debugMode, setDebugMode] = useState(false);

  const ALLOWED_LAYOUTS_MINIMUM = [
    LAYOUT_TYPE.STANDARD,
    LAYOUT_TYPE.INVERSED,
    LAYOUT_TYPE.LOGO_ONLY, 
    'disabled'
  ];

  useEffect(() => {
    setDebugMode(Boolean(window?.localStorage?.getItem("widgetDebugMode")));
  }, [debugMode]);

  useEffect(() => {
    const insertScript = () => {
      const script = document.createElement("script");
      script.src = `${appUrl}/assets/content.js`;
      script.charset = "utf-8";
      script.async = true;
      document.head.appendChild(script);
    };

    insertScript();
  });

  const initialValues = {
    platform: "directintegration",
    currency: "AUD",
    layout: "standard",
    paymentOption: "equal",
    promotionMonths: 12,
    environment: "test",
    merchantId: "[YOUR_MERCHANT_ID]",
    containerClass: "",
    minAmount: "250",
    price: 1000,
  };

  if (debugMode) {
    return <WidgetTesting />;
  }

  let validateLayout = (layout, paymentOption) => {
    if (paymentOption === PAYMENT_OPTIONS.MINIMUM){
      if (!ALLOWED_LAYOUTS_MINIMUM.includes(layout))
        return LAYOUT_TYPE.STANDARD;
      return layout;
    }
    return layout;
  }

  return (
    <Form initialValues={initialValues}>
      {({state}) => (
        <Scene>
          <Scene.Item>
            <InfoCard
              title="Widget playground"
              description="Customise look and feel of widgets to suit your website.">
              <Preview
                merchant={{
                  currency: state.values.currency,
                  layout: validateLayout(state.values.layout, state.values.paymentOption),
                  paymentOption: state.values.paymentOption,
                  promotionMonths: state.values.promotionMonths,
                  price: state.values.price,
                  minAmount: state.values.minAmount,
                }}
              />
              <Configuration selectedPayment={state.values.paymentOption} />
            </InfoCard>
          </Scene.Item>

          <Scene.Item>
            <Instructions
              selection={{
                platform: state.values.platform,
                currency: state.values.currency,
                layout: validateLayout(state.values.layout, state.values.paymentOption),
                promotionMonths: state.values.promotionMonths,
                paymentOption: state.values.paymentOption,
                environment: state.values.environment,
                merchantId: state.values.merchantId,
                minAmount: state.values.minAmount,
                containerClass: state.values.containerClass,
              }}
            />
            <AdvancedConfig />
          </Scene.Item>
        </Scene>
      )}
    </Form>
  );
};

export default Widget;
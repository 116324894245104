import { featureToggles } from "../../../../core/config";
import { onLearnMoreClick } from "../../../../../assets/utils";
import { isBrowser } from "../../../../utils";

const generateHTML = (config) => {
  const containerIdentifier = "latitude-banner-container";

  const environmentAppURL = {
    test:
      "https://develop.checkout.test.merchant-services-np.lfscnp.com/assets/content.js",
    prod: "https://checkout.latitudefinancial.com/assets/content.js",
  };

  const containerHTML = `<div id="${containerIdentifier}"${
    config.containerClass.length > 1 ? ` class="${config.containerClass}"` : ""
  }></div>`;
  if (isBrowser()) {
    window.LatitudeCheckout = {
      merchantId: config.merchantId,
      page: "product",
      container: containerIdentifier,
      containerClass: config.containerClass,
      currency: config.currency,
      minAmount: config.minAmount,
      layout: config.layout,
      ...(featureToggles.isPriceBreakdownEnabled
        ? { paymentOption: config.paymentOption }
        : {}),
      promotionMonths: config.promotionMonths,
      product: {
        id: "",
        name: "",
        category: "",
        price: 1000.0,
        sku: "",
      },
    };

    const learnMoreClickEventHandler = (url) => onLearnMoreClick(url);

    window.LatitudeCheckout.eventHandlers = {
      ...window.LatitudeCheckout.eventHandlers,
      learnMoreClickEventHandler,
    };
  }

  const formattedConfig = JSON.stringify(
    {
      merchantId: config.merchantId,
      page: "product",
      container: containerIdentifier,
      containerClass: config.containerClass,
      currency: config.currency,
      ...(config.paymentOption === "equal" && { minAmount: config.minAmount }),
      layout: config.layout,
      ...(featureToggles.isPriceBreakdownEnabled
        ? { paymentOption: config.paymentOption }
        : {}),
      promotionMonths: config.promotionMonths,
      product: {
        id: "",
        name: "",
        category: "",
        price: "",
        sku: "",
      },
    },
    null,
    6
  );

  const scriptHTML = `
window.LatitudeCheckout = ${formattedConfig};

(function () {
    function asyncLoad() {
        var curr = document.createElement("script");
        curr.type = "text/javascript";
        curr.async = true;
        curr.src = "${
          config.environment === "test"
            ? environmentAppURL.test
            : environmentAppURL.prod
        }?platform=direct&merchantId=${config.merchantId};"
    
        var scr = document.getElementsByTagName("script")[0];
        scr.parentNode.insertBefore(curr, scr);
    }
    
    if (window.attachEvent) {
        window.attachEvent("onload", asyncLoad);
    } else {
        window.addEventListener("load", asyncLoad, false);
    }
})();`;

  const jsonScriptHTML = JSON.stringify(
    {
      productWidget: {
        layout: config.layout,
        paymentOption: config.paymentOption,
        ...(featureToggles.isPriceBreakdownEnabled
          ? { paymentOption: config.paymentOption }
          : {}),
        promotionMonths: config.promotionMonths,
        containerClass: config.containerClass,
        ...(config.paymentOption === "equal" && {
          minAmount: config.minAmount,
        }),
      },
    },
    null,
    6
  );

  return {
    container: containerHTML,
    script: scriptHTML,
    jsonScript: jsonScriptHTML,
  };
};

export default generateHTML;

import React from "react";
import { Container, Link, List, Stack, Text } from "basis";

const Shopify = () => (
  <List type="steps">
    <List.Item>
      <Container padding="0 0 4 0">
        <Text>
          Install and activate Shopify app from{" "}
          <Link
            href="https://apps.shopify.com/latitude-promotions"
            newTab={true}
          >
            here
          </Link>
        </Text>
      </Container>
    </List.Item>

    <List.Item>
      <Container padding="0 0 4 0">
        <Stack gap="4">
          <Text>Customise widget from</Text>

          <Text textStyle="body2">Apps &gt; Latitude Promotions</Text>
        </Stack>
      </Container>
    </List.Item>
  </List>
);

export default Shopify;

import React from "react";
import styled from "styled-components";
import { Container, Flex, Icon, Stack, Text, Select } from "basis";

import { PLATFORMS, PLATFORM_TYPE } from "../../../../core/constants";
import generateHTML from "../utils/generateHTML";

import Direct from "./Direct";
import Magento2 from "./Magento2";
import Woocommerce from "./WooCommerce";
import Shopify from "./Shopify";

const StyledDiv = styled.div`
  label {
    padding: 5px 10px;
  }
`;

const PLATFORM_OPTIONS = Object.keys(PLATFORMS).map((key) => PLATFORMS[key]);

const Instructions = ({ selection = {} }, { platformSelection = () => {} }) => {
  const { container, script, jsonScript } = generateHTML(selection);

  return (
    <Container bg="white">
      <Container bg="white" padding="4 6">
        <Flex height="100%">
          <Text textStyle="subtitle2">
            <strong>Select Platform</strong>
          </Text>
        </Flex>
        <Stack gap="4">
          <StyledDiv>
            <Select
              name="platform"
              options={PLATFORM_OPTIONS}
              onChange={platformSelection()}
            />
          </StyledDiv>
        </Stack>
      </Container>
      <Container bg="secondary.lightBlue.t15" padding="4 6">
        <Stack direction="horizontal" margin="4 0">
          <Icon name="assistance" color="black" />

          <Flex height="100%" placeItems="center">
            <Text textStyle="subtitle2">
              <strong>Instructions</strong>
            </Text>
          </Flex>
        </Stack>

        {selection.platform === PLATFORM_TYPE.DIRECT && (
          <Direct container={container} script={script} />
        )}

        {selection.platform === PLATFORM_TYPE.MAGENTO2 && (
          <Magento2 jsonScript={jsonScript} />
        )}

        {selection.platform === PLATFORM_TYPE.WOOCOMMERCE && (
          <Woocommerce jsonScript={jsonScript} />
        )}

        {selection.platform === PLATFORM_TYPE.SHOPIFY && <Shopify />}
      </Container>
    </Container>
  );
};

export default Instructions;

import React from "react";
import { Container, Accordion, List, Stack, Text } from "basis";

export const AdvancedConfig = () => {
  return (
    <Container bg="secondary.lightBlue.t15" padding="4 6">
      <Accordion>
        <Accordion.Item>
          <Accordion.Item.Header>Advanced Configuration</Accordion.Item.Header>
          <Accordion.Item.Content>
            <Stack gap="4">
              <Text textStyle="body2">
                To dynamically update widget when there's a change to product's price because of product variant or discounts:
              </Text>
            
              <List textStyle="body2">
                <List.Item>
                  Update <strong>window.LatitudeCheckout</strong> variable with the new price value. 
                  <br/> 
                  i.e. <strong>window.LatitudeCheckout.product.price = "[UPDATED_PRICE]"</strong>
                </List.Item>
                <List.Item>
                  Then call{" "}
                  <strong>window.LatitudeCheckout.renderProductBanner()</strong>{" "}
                  to re-render product widget based on that new value.
                </List.Item>
              </List>

              <Text textStyle="body2">
                If further configuration of the Latitude Checkout Widget is
                required, please contact your Relationship Manager to discuss
                requirements.
              </Text>
            </Stack>
          </Accordion.Item.Content>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default AdvancedConfig;
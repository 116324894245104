import React from "react";
import { Container, Stack, Text } from "basis";

import { Banner } from "../../../../assets/components/product";
import { MERCHANTID } from "../../../core/constants";
import { MIN_PRODUCT_PRICE, MAX_PRICE_MAP } from "../../../../assets/constants";
import { shouldShowPriceBreakdown } from "../../../../assets/components/product/utils";

export const Preview = ({ merchant = {} }) => {
  const { allowPriceBreakdown, defaultWidget } = shouldShowPriceBreakdown({
    price: Number(merchant?.price),
    minAmountOverride: Number(MIN_PRODUCT_PRICE),
    minAmount: MIN_PRODUCT_PRICE,
    paymentOption: merchant?.paymentOption,
    priceThreshold: MAX_PRICE_MAP[Number(merchant?.promotionMonths)],
    currency: merchant?.currency,
  });

  return (
    <Container bg="white" padding="4 6">
      <Stack gap="4">
        <Text textStyle="body2">
          <strong>Preview for a $1000 product</strong>
        </Text>
        <div
          id="latitude-banner-container"
          dangerouslySetInnerHTML={{
            __html: Banner({
              merchantId: MERCHANTID.MOCK_MERCHANT,
              layout: merchant?.layout,
              currency: merchant?.currency,
              containerClass: "",
              price: 1000,
              paymentOption: merchant?.paymentOption,
              promotionMonths: merchant?.promotionMonths,
              minAmount: merchant?.minAmount,
              allowPriceBreakdown: allowPriceBreakdown,
              defaultWidget: defaultWidget,
            }),
          }}></div>
      </Stack>
    </Container>
  );
};

export default Preview;

import { EVENTS, ACTION } from "../../src/core/constants";
import { getAppContext } from "./logging-helper";
import { logger } from "../../src/core/logger";
import * as module from "../utils";

export const onLearnMoreClick = (applyURL) => {
  const appContext = getAppContext();
  const eventDetails = {
    ...appContext,
    params: {
      applyURL,
    },
  };
  
  module.onEventTrigger(EVENTS.LearnMore, ACTION.Click, eventDetails, () => {
    window.open(applyURL, "_blank");
  });
};

export const onEventTrigger = (eventName, action, eventDetails, func) => {
  const info = {
    event: eventName,
    action: action,
    eventDetails,
  };

  logger.info(info);
  
  if (func) {
    func();
  }
};

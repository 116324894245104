import React from "react";
import {
  Container,
  RadioGroup,
  Select,
  Stack,
  Input,
  Accordion,
  Text,
} from "basis";
import { PAYMENT_OPTIONS } from "../../../core/constants";

const currencyOptions = [
  { label: "AUD", value: "AUD" },
  { label: "NZD", value: "NZD" },
];

const layoutOptions = [
  { label: "Standard", value: "standard" },
  { label: "Single line", value: "single-line" },
  { label: "Single line inversed", value: "single-line-inversed" },
  { label: "Inversed", value: "inversed" },
  { label: "Logo only", value: "logo-only" },
  { label: "Disabled", value: "disabled" },
];

const layoutOptionsMin = [
  { label: "Standard", value: "standard" },
  { label: "Inversed", value: "inversed" },
  { label: "Logo only", value: "logo-only" },
  { label: "Disabled", value: "disabled" },
];

const paymentOptions = [
  { label: "Equal Monthly", value: PAYMENT_OPTIONS.EQUAL },
  { label: "Minimum Monthly or Flexible", value: PAYMENT_OPTIONS.MINIMUM },
];

const promotionMonthsOptions = [
  { label: "6 Months", value: "6" },
  { label: "12 Months", value: "12" },
  { label: "18 Months", value: "18" },
  { label: "24 Months", value: "24" },
  { label: "36 Months", value: "36" },
  { label: "48 Months", value: "48" },
  { label: "50 Months", value: "50" },
  { label: "60 Months", value: "60" },
];

const environmentOptions = [
  { label: "Test", value: "test" },
  { label: "Production", value: "prod" },
];

export const Configuration = ({ onChange = () => { }, selectedPayment }) => {
  return (
    <Container bg="secondary.lightBlue.t15" padding="4 6">
      <Stack gap="4">
        <RadioGroup
          name="currency"
          color="white"
          label="Base currency"
          options={currencyOptions}
        />
        <Select
          name="layout"
          label="Layout"
          options={
            selectedPayment === PAYMENT_OPTIONS.MINIMUM ?
              layoutOptionsMin : layoutOptions
          }
          onChange={onChange}
          margin="3 0"
        />

        <Select
          name="paymentOption"
          label="Interest Free plan type"
          options={paymentOptions}
          onChange={onChange}
          margin="3 0"
        />

        <Select
          name="promotionMonths"
          label="Payment plan period"
          options={promotionMonthsOptions}
          onChange={onChange}
          margin="3 0"
        />
        <RadioGroup
          name="environment"
          color="white"
          label="Environment"
          options={environmentOptions}
        />
        <Input name="merchantId" color="white" label="Merchant ID" />
        <Accordion>
          <Accordion.Item>
            <Accordion.Item.Header>Advanced Fields</Accordion.Item.Header>
            {selectedPayment === PAYMENT_OPTIONS.EQUAL && (
              <Accordion.Item.Content>
                <Text margin="0 0 3">
                  The widget won't show for products under this amount. Must be
                  over $250.
                </Text>
                <Input
                  name="minAmount"
                  label="Minimum amount for widget to show"
                  optional
                />
              </Accordion.Item.Content>
            )}
            <Accordion.Item.Content>
              <Text margin="0 0 3" prefix="$">
                By adding a CSS selector, you can use this to override any
                default settings. This must be a valid CSS selector eg:
                "example-class-name". For further information, please contact
                Latitude support.
              </Text>
              <Input name="containerClass" label="Container Class" optional />
            </Accordion.Item.Content>
          </Accordion.Item>
        </Accordion>
      </Stack>
    </Container>
  );
};

export default Configuration;